import { Box } from '@mui/material';
import RootState from '@src/interfaces/RootState';
import * as SelectInterfaces from '@src/interfaces/SelectInterface';
import { useEffect } from 'react';
import cookie from 'react-cookies';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { required } from 'redux-form-validators';
import renderSelectField from '../form/Select';

const languageList: SelectInterfaces.SelectList = [
    // { name: 'EN', value: 'en', label: 'EN' },
    { name: 'FR', value: 'fr', label: 'FR' },
    { name: 'NL', value: 'nl', label: 'NL' },
    { name: 'EN', value: 'en', label: 'EN' },
];

const SelectLanguage = () => {
    const currentLng = languageList.find((f) => f.value === cookie.load('language'));
    const { i18n } = useTranslation('common');
    const { t } = useTranslation('common');
    const storedLng = useSelector((state: RootState) => state?.form?.loginForm?.values?.selectedLanguage);

    useEffect(() => {
        if (storedLng !== undefined) {
            i18n.changeLanguage(storedLng);
            localStorage.setItem('language', storedLng);
        }
    }, [storedLng]);

    return (
        <Box className="form__form-group" sx={{ marginTop: '16px', marginBottom: '8px' }}>
            <div className="form__form-group-field">
                <div id="languageSelector" className="form__form-group-field">
                    <Field
                        name="selectedLanguage"
                        component={renderSelectField}
                        options={languageList}
                        type="hidden"
                        placeholder={t('login.language')}
                        value="selectedLanguage"
                        defaultValue={currentLng?.label ?? 'en'}
                        validate={[required({ msg: t('validations.required') })]}
                        isDisabled={true}
                    ></Field>
                </div>
            </div>
        </Box>
    );
};

export default SelectLanguage;
